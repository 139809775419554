<template>
    <div class="card" style="margin-button:10px;">
        <header class="card-header">
            <p class="card-header-title">
                <span class="icon">
                    <i class="far fa-check-circle"></i>
                </span>
                <span>Roles</span>
            </p>
        </header>
        <div class="card-content">
            <div class="columns">
                <div class="column">
                    <div class="field has-addons">
                        <p class="control has-icons-left is-expanded">
                            <input type="text" class="input" v-model="filter" placeholder="Filtrar...">
                            <span class="icon is-small is-left">
                                <i class="fas fa-filter"></i>
                            </span>
                        </p>
                        <p class="control">
                            <button type="button" class="button is-primary" v-on:click="onClearFilter()">
                                <i class="fas fa-times"></i>
                            </button>
                        </p>
                    </div>
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <table class="table is-hoverable is-fullwidth">
                        <thead>
                            <tr>
                                <th>Role</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(role, key) in filteredRoles" v-bind:key="key">
                                <td>
                                    <b-checkbox type="is-warning"
                                            v-model="selectedRoles"
                                            v-bind:key="key"
                                            :native-value="role.id">
                                        {{ role.display_name }}
                                    </b-checkbox>
                                </td>
                                <td>{{role.description}}</td>
                            </tr>    
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>        
</template>
<script>
export default {
    name: 'UsersRoles',
    props: [
        'roles',
        'roleIds'
    ],
    data() {
        return {
            filter: null,
            selectedRoles: []
        };
    },
    methods: {
        onClearFilter() {
            this.filter = null;
        }
    },
    computed: {
        filteredRoles() {
            if (this.filter !== null) {
                return this.roles.filter(role => {
                    return role.display_name.toLowerCase().includes(this.filter.toLowerCase())
                });
            }
            return this.roles;
        }
    },
    watch: { 
      	roleIds: function(newVal, oldVal) { // watch it
            this.selectedRoles = newVal;
        },
        selectedRoles: function(newVal, oldVal) { // watch it
            this.$emit("onSelectedRoles", newVal);
        }
    }
}
</script>