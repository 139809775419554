import Model from "../model"

export default class User extends Model
{
    constructor(attr = null) {
        super();
        this.first_name = null;
        this.last_name = null;
        this.email = null;
        this.license_number = null;
        this.password = null;
        this.client_id = 1;
        this.role_ids = [];
        if (attr) {
            this.fill(attr)
        }
    }
    // ---------------------------- GETTERS ---------------------------
    get hasRoles() {
        return this.role_ids.length > 0;
    }
}