<template>
    <section>
        <vue-headful :title="`FLPN Aviasolutions - ${pageTitle}`"></vue-headful>
        <h1 class="title">{{ pageTitle }}</h1>
      <contenedor>
        <form action="#" autocomplete="off" v-on:submit.prevent="save">
            <div class="columns">
                <div class="column">
                    <label class="label">First Name</label>
                    <input type="text"
                           class="input"
                           v-model="user.first_name"
                           v-validate="'required|min:4'">
                </div>
                <div class="column">
                    <label class="label">Last Name</label>
                    <input type="text"
                           class="input"
                           v-model="user.last_name"
                           v-validate="'required|min:4'">
                </div>
                <div class="column">
                    <label class="label">Email</label>
                    <input type="email"
                           class="input"
                           v-model="user.email"
                           v-validate="'required|email'">
                </div>
                <div class="column">
                    <label class="label">License Number</label>
                    <input type="text"
                           class="input"
                           v-model="user.license_number">
                </div>
            </div>
            <div class="columns">
                <div class="column is-3">
                    <label class="label">Password</label>
                    <input type="password"
                           class="input"
                           v-model="user.password"
                           v-validate="!user.exists ? 'required|min:5' : ''">
                </div>
                <div class="column is-3">
                    <label class="label">Client</label>
                    <div class="select is-fullwidth">
                        <select placeholder="Selection..."
                                v-model="user.client_id"
                                :disabled="disableEditClient">
                            <option v-for="client in clients"
                                    :value="client.id"
                                    :key="client.id">
                                {{ client.name }}
                            </option>
                        </select>
                    </div>    
                </div>
            </div>    
            <div class="columns">
                <div class="column">
                    <users-roles :roles="roles"
                                 :roleIds="this.user.role_ids"
                                 v-on:onSelectedRoles="selectedRoles">
                    </users-roles>
                </div>
            </div>    
            <div class="field is-grouped is-grouped-right">
                <p class="control">
                    <router-link :to="'/users'" class="button is-light">
                        <b-icon pack="fas" icon="long-arrow-alt-left"></b-icon>
                        <span class="has-text-weight-bold is-uppercase">Back to Users</span>
                    </router-link>
                </p>
                <p class="control">
                    <button type="submit" class="button is-primary" :disabled="!this.user.hasRoles">
                        <b-icon pack="far" icon="save"></b-icon>
                        <span class="has-text-weight-bold is-uppercase">Save</span>
                    </button>
                </p>
            </div>
        </form>
      </contenedor>
    </section>
</template>
<script>
import UsersService from "../../services/users.service";
import ClientsService from "../../services/clients.service";
import FailedResponse from "../../helpers/failed-response.helper";
import User from "../../models/user/user.model";

import UsersRoles from "./UsersRoles";
import Contenedor from "@/components/Contenedor";

export default {
    name: "UsersCreator",
    data() {
        return {
            pageTitle: 'Create User',
            usersService: new UsersService(),
            clientsService: new ClientsService(),
            user: new User({
                client_id: this.$auth.user().client_id
            }),
            roles: [],
            clients: []
        };
    },
    components: {
      Contenedor,
        UsersRoles
    },
    computed: {
        disableEditClient() {
            return !this.$auth.check('admin');
        }
    },
    methods: {
        reset: function() {
            const currentUser = this.$auth.user();
            this.user = new User({
                client_id: currentUser.client_id
            });
            this.$validator.reset();
        },
        selectedRoles(roleIds) {
            this.user.fill({
                role_ids: roleIds
            });
        },
        save: function() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    const loadingComponent = this.$buefy.loading.open({container: null})
                    this.usersService.save(this.user).then(
                        ({data}) => {
                            if (!this.user.exists) {
                                this.reset();
                            } else {
                                this.user.fill(data.user)
                            }
                            loadingComponent.close();
                            this.$buefy.toast.open({
                                message: data.notificacion,
                                type: 'is-success'
                            });
                        },
                        (failed) => {
                            loadingComponent.close();
                            const messages = FailedResponse.getMessage(failed);
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: messages,
                                type: 'is-danger'
                            });
                        }
                    );
                }
            });
        }
    },
    watch: {
        '$route' (to) {
            if (to.path === '/users/create') {
                this.reset();
            }
        }
    },
    mounted() {
        this.usersService.findRoles().then(
            ({data}) => {
                this.roles = data;
            }
        );
        this.clientsService.findAll().then(
            ({data}) => {
                this.clients = data.data;
            }
        );
        // Otras Funciones: mounted, created, udpated, beforeCreate, beforeUpdate
        // this.$forceUpdate();  // Notice we have to use a $ here
        if (this.$route.params.id) {
            const loadingComponent = this.$buefy.loading.open({container: null})
            this.usersService.findOne(this.$route.params.id, {edit: true}).then(
                ({data}) => {
                    this.pageTitle = `Edit User: ${data.full_name}`;
                    this.user.fill(data);
                    loadingComponent.close();
                },
                () => {
                    loadingComponent.close();
                    this.$buefy.toast.open({
                        message: 'User not found.',
                        type: 'is-danger'
                    });
                }
            );
        }
    }
}
</script>